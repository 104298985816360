@import "abstracts.scss";

.container {
  padding: 0 2rem;
  overflow: hidden;
}
.c164 {
  position: relative;
  @include get-vw(padding-left, 164, 120);
  @include get-vw(padding-right, 164, 120);
  @media (max-width: 959px) {
    padding-left: 60px;
    padding-right: 60px;
  }
  @media (max-width: 767px) {
    padding-left: 32px;
    padding-right: 32px;
  }
}
.heroHeader {
  letter-spacing: -1.8 px;
  line-height: 1;
}
.heroP {
  letter-spacing: -0.1 px;
  line-height: 1.33;
}
.main {
  min-height: 100vh;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background: #180B36;
}

.landingsection {
  position: relative;
  z-index: 2;
  padding-top: 195px;
  h1 {
    span {
      &:last-child {
        background: linear-gradient(135deg, #AEC3FF 0%, #FFF 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
}

// Hero area video
.herovideo {
  padding-top: 128px;
  padding-bottom: 116px;
  width: 100%;
}
.videowrapper {
  position: relative;
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
}
.coverwrapper {
  position: relative;
  z-index: 3;
  border: 1px solid #9DB4F0;
  aspect-ratio: 1/0.504;
  width: 100%;
  height: auto;
  border-radius: 10px;
  overflow: hidden;
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
    position: relative;
    cursor: pointer;
  }
}
.gradone {
  border-radius: 50%;
  width: 59.2%;
  height: auto;
  aspect-ratio: 1/1;
  position: absolute;
  top: -3.9%;
  left: -8.5%;
  transform: rotate(-27.12deg);
  opacity: 0.2;
  background: #9B9CFF;
  filter: blur(150px);
}
.gradtwo {
  border-radius: 50%;
  width: 54.25%;
  height: auto;
  aspect-ratio: 1/1;
  position: absolute;
  bottom: -10.92%;
  right: -8.79%;
  transform: rotate(-27.12deg);
  opacity: 0.2;
  background: #D79DDA;
  filter: blur(150px);
}
.playcover {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(180deg, #190B36 0%, rgba(25, 12, 55, 0.29) 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
}
.playbutton {
  width: 123px;
  height: 123px;
  position: absolute;
  svg {
    pointer-events: none;
  }
  span {
    transition: transform 1.3s $ease-out-expo;
  }
  &:hover {
    span {
      transition: transform .8s $ease-out-expo;
      transform: scale(0.73);
    }
  }
}
.dottedline {
  width: calc(100% + 46px);
  height: calc(100% + 46px);
  left: -23px;
  top: -23px;
  border: 1px dotted #f9f9f9;
  border-radius: 50%;
  position: absolute;
  opacity: .4;
  pointer-events: none;
}
.bgwrapper {
  position: absolute;
  z-index: 1;
  width: 131%;
  height: 169%;
  top: -34.5%;
  left: -15.5%;
  img {
    object-fit: contain;
  }
}
.framewrapper {
  width: calc(100% + 32px);
  height: calc(100% + 32px);
  left: -16px;
  top: -16px;
  position: absolute;
  z-index: 2;
}
.cover {
  //position: a;
  z-index: 2;
}
@media (max-width: 767px) {
  .herovideo {
    padding-top: 82px;
  }
  .playbutton {
    width: 90px;
    height: 90px;
    svg {
      width: 100%;
      height: 100%;
    }
  }
  .coverwrapper {
    border-radius: 5px;
  }
  .dottedline {
    width: calc(100% + 24px);
    height: calc(100% + 24px);
    left: -12px;
    top: -12px;
  }
  .framewrapper {
    width: calc(100% + 20px);
    height: calc(100% + 22px);
    left: -10px;
    top: -11px;
  }
}

// how it works section
.howitworks {
  padding-bottom: 123px;
  position: relative;
  z-index: 3;
  h3 {
    margin-bottom: 64px;
    background: linear-gradient(135deg, #AEC3FF 0%, #FFF 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    line-height: 1.14;
    letter-spacing: -1.8px;
  }
  p:first-of-type {
    margin-top: 26px;
    letter-spacing: -0.1 px;
    line-height: 1.33;
  }
  p:last-of-type {
    margin-top: 16px;
    max-width: 276px;
    color: #EEE3FF;
    line-height: 1.5;
  }
  @include bp-767 {
    padding-bottom: 50px;
    p:first-of-type {
      margin-top: 16px;
    }
    p:last-of-type {
      margin-top: 8px;
    }
  }
}
.howicon {
  width: 85px;
  margin: 0 auto;
  position: relative;
  img {
    object-fit: contain;
  }
}

// Card trio section
.cardtrio {
  padding-bottom: 82px;
  position: relative;
  h4 {
    background: linear-gradient(135deg, #AEC3FF 0%, #FFF 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    line-height: 1.14;
    letter-spacing: -1.8px;
  }
  p {
    line-height: 1.5;
  }
  img {
    width: 100%;
  }
  @include bp-767 {
    padding: 0 20px 82px;
  }
}
.landingcard {
  position: relative;
  border-radius: 25px;
  overflow: hidden;
  z-index: 2;
  p {
    font-size: 16px;
  }
}
.cardcontent {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding-top: 48px;
}
.cardbg {
  position: absolute;
  width: 100%;
  height: 100%;
}
.largecard {
  width: 100%;
  height: auto;
  aspect-ratio: 2.11/1;
  p {
    margin-top: 18px;
  }
}
.smallcard {
  height: auto;
  aspect-ratio: 0.932/1;
  > div {

  }
  p {
    margin-top: 14px;
  }
}
.cardblur {
  opacity: 0.2;
  background: #D79DDA;
  filter: blur(250px);
  width: 100%;
  border-radius: 50%;
  height: auto;
  aspect-ratio: 1/1;
  top: -107px;
  position: absolute;
  z-index: 1;
}
@media (max-width: 767px) {
  .largecard, .smallcard {
    aspect-ratio: .93/1;
  }
  .cardcontent {
    padding-top: 30px;
  }
  .cardtrio {
    p {
      width: 85%;
      max-width: 290px;
    }
  }
}

// Prefooter area
.prefooter {
  position: relative;
  h4 {
    background: linear-gradient(135deg, #AEC3FF 0%, #FFF 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    line-height: 1.14;
    letter-spacing: -1.8px;
    position: relative;
    z-index: 2;
  }
  p {
    font-size: 16px;
    margin-top: 16px;
    margin-bottom: 32px;
    line-height: 1.5;
    position: relative;
    z-index: 2;
  }
}
.prefooterGridWrapper {
  position: absolute;
  bottom: 0;
  left: -37%;
  width: 174%;
  img {
    opacity: .6;
  }
  @include bp-767 {
    top: 10%;
    left: -15%;
    width: 130%;
  }
}
.prefooterGradient {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: radial-gradient(48.44% 48.39% at 50% 50%, rgba(24, 11, 54, 0.00) 7.81%, #180B36 93.75%);
}
.buttonduo {
  gap: 8px;
  position: relative;
  z-index: 2;
  @include bp-767 {
    flex-direction: column;
    gap: 18px;
    button {
      margin: 0;
    }
  }
}

// Footer
.footer {
  display: flex;
  flex: 1;
  padding: 2rem 0;
  border-top: 1px solid #eaeaea;
  justify-content: center;
  align-items: center;
}
.cardmerch {
  position: relative;
  width: 795px;
  overflow: hidden;
}
.assetcard {
  width: 294px;
  height: 368px;
  position: relative;
  bottom: -112px;
  z-index: 1;
}
.assetcardtwo {
  left: 30%;
  bottom: -55px;
  position: absolute;
  z-index: 2;
}
.assetmerch {
  position: absolute;
  z-index: 3;
}
.assetmerchone {
  width: 132px;
  height: 249px;
  bottom: -84px;
  left: 20%;
  transform: rotate(-10.68deg);
}

.assetmerchtwo {
  width: 250px;
  height: 359px;
  bottom: -100px;
  right: 20%;
  transform: rotate(20.27deg);
}
.footer a {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  color: #33336c;
}

.grid {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 800px;
  color: #33336c;
}

.card {
  margin: 1rem;
  padding: 1.5rem;
  text-align: left;
  color: inherit;
  text-decoration: none;
  border: 1px solid #33336c;
  border-radius: 10px;
  transition: color 0.15s ease, border-color 0.15s ease;
  max-width: 300px;
}

.card:hover,
.card:focus,
.card:active {
  color: #9b9cee;
  border-color: #9b9cee;
}

.card h2 {
  margin: 0 0 1rem 0;
  font-size: 1.5rem;
}

.card p {
  margin: 0;
  font-size: 1.25rem;
  line-height: 1.5;
}

.logo {
  height: 1em;
  margin-left: 0.5rem;
}

@media (max-width: 600px) {
  .grid {
    width: 100%;
    flex-direction: column;
  }
}

@media (prefers-color-scheme: dark) {
  .card,
  .footer {
    border-color: #222;
  }
  .code {
    background: #111;
  }
  .logo img {
    filter: invert(1);
  }
}
